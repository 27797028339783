/* src/index.css */
@tailwind base;
html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;

/* App.css */
header {
  height: 70px; /* Adjust the height to match your navbar */
  /* background-color: red; */
}

