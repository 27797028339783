.sidebar-custom > div {
    background-color: #EFFFEF !important;
    
  }
  
  .sidebar-custom .active-tab {
    background-color: #dbffdb !important;
  }
  
  .sidebar-custom .active-tab span {
    color: #47AE4D !important;
  }